import React from "react"
import { Link } from "gatsby"
import Icon from "../images/svg/checkout-failure.svg"

import styles from "./checkout-failure.module.scss"

import Layout from "../components/layout"

const CheckoutSuccess = () => {
  return (
    <Layout>
      <main className={styles.container}>
        <h1 className={styles.title}>Oops ! Une erreur s'est produite.</h1>
        <Icon className={styles.image} />
        <Link to="/panier">Réessayer</Link>
      </main>
    </Layout>
  )
}

export default CheckoutSuccess
